import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import AddToCalendar from 'react-add-to-calendar'

import Link from '../utils/link'

import Seo from '../components/seo'
import PropertySlider from '../components/property-slider'
import Map from '../components/map'
import Cta from '../components/cta'
import Play from '../assets/images/play.svg'
import Sold from '../assets/images/sold.svg'

import FsLightbox from 'fslightbox-react';
import moment from 'moment'

function filterIt(arr, searchKey) {
  return arr.filter(function(obj) {
    return Object.keys(obj).some(function(key) {
      return obj[key].includes(searchKey);
    })
  });
}

class PropertyTemplate extends Component {

  state = {
    videoLightbox: false,
    floorplanLightbox: false
  }

  renderText = (el, i) => {
    if (el.length === 0) return
    if (el.length < 20 && el.indexOf('•') === -1) return <h4 key={i}>{ el }</h4>
    if (el.indexOf('•') !== -1) return <li key={i}>{ el.replace(/•/gi, '') }</li>
    return <p key={i}>{ el }</p>
  }

  render() {

    let page = this.props.data.wpProperty
    let { videoLightbox, floorplanLightbox } = this.state
    const images = page?.propertyImages?.map(el => el.large)
    const floorplan = page?.propertyFloorplan?.map(el => el.url)
    let team = this.props.data.allWpTeamMember.nodes
    let people = []
    let videoId = ''
    let location = {
      latitude: page.propertyLocation.lat,
      longitude: page.propertyLocation.long
    }

    let cta = this.props.data.wp.siteSettings.acf.cta

    let video = page.propertyExternal?.length && filterIt(page.propertyExternal,'youtube')
    if (video.length) {
      videoId = video[0].url.split('=')[1]
    }

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={'single-property'} description={page.seo.metaDesc} />
        <article className='single-property__wrapper'>
          <PropertySlider images={images} floorplan={floorplan} />
          <section className='single-property__header'>
            <div className='single-property__inner'>
              <div className='single-property__main'>
                <div className='single-property__main-title'>
                  {page.propertyListing.status === "Settled" && <img className='sold' src={Sold} alt='Mara+C - Sold' /> }
                  {page.propertyListing.status === "Unconditional" && <img className='sold' src={Sold} alt='Mara+C - Sold' /> }
                  <h1>{page.propertyAddress?.streetAddress}<br />{page.propertyAddress?.suburb} {page.propertyAddress?.state}</h1>
                </div>
              </div>
              <div className='single-property__sidebar'>
                <ul className='single-property__specs'>
                  <li>{page.propertyData?.bedrooms} Bed</li>
                  <li>{page.propertyData?.bathrooms} Bath</li>
                  <li>{page.propertyData?.totalParking} Car</li>
                </ul>
                { page.propertyFloorplan.length > 0 &&
                  <button className='btn btn--outline' onClick={() => this.setState({ floorplanLightbox: !floorplanLightbox })}>
                    Floorplan
                  </button>
                }
              </div>
            </div>
          </section>
          <section className='single-property__body'>
            <div className='single-property__inner'>
              <div className='single-property__main'>
                {page.propertyListing.status === "Settled" && <p className='single-property__price'>{page.propertyListing.displayPrice}</p> }
                {page.propertyListing.status === "Unconditional" && <p className='single-property__price'>{page.propertyListing.displayPrice}</p> }
                <h2>{ page.propertyListing?.mainHeadline }</h2>
                <div className='single-property__content'>
                  { page.propertyListing?.mainDescription.split('\n').map(this.renderText) }
                </div>
              </div>
              <div className='single-property__sidebar'>
                {page.propertyListing?.auctionDate &&
                  <div className='single-property__auction'>
                    <h4>Auction</h4>
                    <div className='single-property__date'>
                      {moment(page.propertyListing?.auctionDate).format('dddd D MMMM h:mmA')}
                    </div>
                  </div>
                }
                { page.propertyInspections?.length > 0 &&
                  <div className='single-property__inspection'>
                    <h4>Inspections</h4>
                    <ul className='single-property__inspections'>
                      { page.propertyInspections.map((el, i) => {
                        if (moment(el.startDate).isAfter(new Date())) return null
                        let event = {
                          title: 'Property Inspection',
                          description: '',
                          location: page.propertyAddress?.streetAddress+' '+page.propertyAddress?.suburb+' '+page.propertyAddress?.state,
                          startTime: moment(el.startDate),
                          endTime: moment(el.endDate)
                        }
                        return (
                          <li key={i}>
                            <AddToCalendar event={event} buttonLabel={moment(el.startDate).format('dddd D MMMM h:mmA') +' - '+moment(el.endDate).format('h:mmA')} />
                          </li>
                        )
                      }) }
                    </ul>
                  </div>
                }
                <div className='single-property__agents'>
                  <h4>Agents</h4>
                  { page.propertyStaff?.length > 0 &&
                    <ul className='single-property__staff'>
                      {page.propertyStaff.map((el, i) => {
                        if (people.includes(el.staffMemberId)) return
                        if (!el.webDisplay) return
                        people.push(el.staffMemberId)
                        console.log(people, team)
                        const member = team.find(
                          (t) => t.teamDetails.id === el.staffMemberId
                        )
                        if (!member) return
                        let item = {
                          url: member.acf?.profileImage?.localFile?.childImageSharp?.original?.src,
                          slug: member.slug,
                          name: member.title,
                          role: member.teamDetails.jobTitle,
                          mobile: member.teamDetails.hideMobileOnWeb
                            ? member.teamDetails.phone
                            : member.teamDetails.mobile,
                          email: member.teamDetails.email,
                        }
                        // Fallback to global phone
                        if (!item.mobile)
                          item.mobile = member.teamDetails.mobile

                        return (
                          <li key={i}>
                            <Link to={`/team/${item.slug}`}>
                              <img src={item.url} alt={member.title} />
                            </Link>
                            <Link to={`/team/${item.slug}`} className='name'>{item.name}</Link>
                            <Link to={`tel:${item.mobile}`} className='mobile'>
                              {item.mobile}
                            </Link>
                            <Link to={`mailto:${item.email}`} className="email">
                              Email Agent
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  }
                </div>
              </div>
            </div>
            <div className='single-property__gallery'>
              <div className='single-property__inner'>
                <h4>Take a look around</h4>
                <div className='single-property__images'>
                  { images.length > 0 && images.map((el, i) => {
                    if ( i > 3 ) return
                    return (
                      <div className='single-property__image' key={i}>
                        <img src={el} alt={page.title} />
                        { video.length > 0 && i === 0 &&
                          <img className='play' src={Play} alt='Play Video' onClick={() => this.setState({ videoLightbox: !videoLightbox })} />
                        }
                      </div>
                    )
                  })}
                </div>
                <div className='single-property__contact'>
                  <div className='single-property__buttons'>
                    <h4>{page.propertyAddress?.streetAddress}<br />{page.propertyAddress?.suburb} {page.propertyAddress?.state}</h4>
                    <ul>
                      <li><Link className='btn' to='/contact'>Get in contact</Link></li>
                    </ul>
                  </div>
                  <div className='single-property__map'>
                    <Map {...location} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </article>
        <Cta {...cta} />
        { video.length > 0 &&
          <FsLightbox
            toggler={videoLightbox}
            sources={[
              <iframe
                frameborder="0"
                width="1920px"
                height="1080px"
                allow="autoplay; fullscreen"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}>
              </iframe>
            ]}
          />
        }
        { floorplan?.length > 0 &&
          <FsLightbox
            toggler={floorplanLightbox}
            sources={floorplan.map((el, i) => (<img src={el} className='floorplan-image' alt='Floorplan' key={i} />))}
          />
        }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpProperty(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      propertyAddress {
        streetAddress
        suburb
        state
      }
      propertyData {
        bedrooms
        bathrooms
        garages
        totalParking
      }
      propertyImages {
        id
        url
        small
        large
      }
      propertyLand {
        landArea_value
        buildingArea_value
      }
      propertyLocation {
        lat
        long
      }
      propertyListing {
        marketingStatus
        status
        auctionDate
        type
        mainDescription
        listedDate
        mainHeadline
        displayPrice
      }
      propertyExternal {
			  order
			  title
			  type
			  url
			}
      propertyFloorplan {
        order
        pdfUrl
        thumbnail
        title
        url
      }
      propertyStaff {
        displayOrder
        role
        staffMemberId
        webDisplay
      }
      propertyInspections {
        endDate
        startDate
      }
    }
    allWpTeamMember {
      nodes {
        title
        slug
        acf {
          profileImage {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          position
        }
        teamDetails {
          email
          firstName
          hideMobileOnWeb
          id
          jobTitle
          lastName
          mobile
          phone
          role
        }
      }
    }
    wp {
      siteSettings {
        acf {
          cta {
            backgroundColour
            backgroundImage {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
          }
        }
      }
    }
  }
`

export default PropertyTemplate
